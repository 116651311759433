import ServiceItem from "../../modules/ServiceItems/ServiceItems";
import { serviceData } from "../../modules/ServiceItems/ServicesData";
import styles from "./styles.module.scss";

const FBMServices = () => {
  return (
    <div className={styles.serviceItemsPage}>
      <h1>[FBM] Ecosystem Services</h1>
      <div className={styles.serviceItems}>
        {serviceData.map((service, index) => (
          <div key={index} className={styles.serviceItemsContainer}>
            <ServiceItem
              img={service.imgSrc}
              title={service.title}
              text={<p>{service.text}</p>}
              linkSpan={service.span}
              buttonText={service.buttonText}
              serviceIcon={service.icon}
              aLink={service.aLink}
              buttonLink={service.buttonLink}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FBMServices;
