import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import styles from "./styles.module.scss";
import clsx from "clsx";
import ContainerNews from "./container/ContainerNews";
import DeclineRate from "../itemBlockInfo/declineRate/DeclineRate";

const NewsContainer = ({ data, isFetching, isLoading }) => {
  return (
    <div className={styles.containerRateAndNews}>
      <DeclineRate data={data} isFetching={isFetching} isLoading={isLoading} />

      <div className={styles.containerNews}>
        <div className={styles.containerTitle}>
          <p className="H3">[FBM] Pay NEWS</p>
          <Link
            className={clsx("buttons", styles.linkTitle)}
            to={PAGE_ROUTES.NEWS}
          >
            All News
          </Link>
        </div>
        <ContainerNews />
      </div>
    </div>
  );
};

export default NewsContainer;
