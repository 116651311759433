import { useForm } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import { LOGIN_REG_EXP, NOTIFICATION_INFO } from "../../constants/constants";
import { useContext, useEffect, useState } from "react";
import { login } from "../../api/authApi";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../constants";
import { AuthContext } from "../../context/AuthContext";
import styles from "./Login.module.scss";
import LogoIcon from "../../icons/LogoIcon";
import TelegramIcon from "../../icons/TelegramIcon";
import EyeOpened from "../../icons/HidePasswordEye";
import { showToast } from "../../modules/Notification/ActionNotification";

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate();
  const { setEmail } = useContext(AuthContext);
  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { email, password } = watch();

  useEffect(() => {
    if (errors && errors.serverError) {
      clearErrors("serverError");
    }
  }, [email, password, errors]);

  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      await login(data);
      setEmail(data.email);
      navigate(PAGE_ROUTES.TWO_FA_AFTER_LOGIN);
      showToast("login", NOTIFICATION_INFO.LOGIN);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        setError("serverError", { message: error.response.data.data.message });
      }
      console.error(error);
    }
  };

  const handleHidePassword = () => {
    setIsClosed((prevIsClosed) => {
      const newIsClosed = !prevIsClosed;
      console.log(newIsClosed);
      return newIsClosed;
    });
  };

  return (
    <div className={styles.login}>
      <div className={styles.loginContainer}>
        <h1 className={styles.logoContainerTitle}>
          Welcome to
          <LogoIcon />
        </h1>
        <div className={styles.loginContainerForm}>
          <form
            onSubmit={handleSubmit(handleLogin)}
            className={styles.loginContainerInputs}
          >
            {errors.serverError && (
              <p className={styles.serverError}>{errors.serverError.message}</p>
            )}

            <div className={styles.loginContainerInput}>
              <label htmlFor="email" className="primaryText">
                E-mail Address
              </label>
              <input
                className="primaryText"
                id="email"
                type="text"
                placeholder="fbmpay@gmail.com"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: LOGIN_REG_EXP,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <p className={styles.fieldErrors}>{errors.email.message}</p>
              )}
            </div>

            <div className={styles.loginContainerInput}>
              <label htmlFor="password" className="primaryText">
                Password
              </label>
              <input
                className="primaryText"
                id="password"
                type={isClosed ? "text" : "password"}
                placeholder="********"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
              />

              <EyeOpened
                className={styles.loginContainerInputEye}
                isClosed={isClosed}
                onClick={handleHidePassword}
              />

              {errors.password && (
                <p className={styles.fieldErrors}>{errors.password.message}</p>
              )}
            </div>

            <Button type="submit" isLoading={isLoading}>
              Login
            </Button>
          </form>
          <div className={styles.loginRecover}>
            <p className={`${styles.loginRecoverText} secondaryText`}>
              Forgot password?
            </p>
            <a
              href="/recover-password"
              className={`${styles.loginRecoverLink} secondaryText`}
            >
              Recover it
            </a>
          </div>
        </div>
        <div className={styles.loginContainerRegistration}>
          <div className={styles.loginContainerRegistrationText}>
            <p className="semiHedline">Registration</p>
            <p className="primaryText">
              To register on platform please contact your manager{" "}
              <a
                href="https://t.me/v_astanin"
                target="_blank"
                className={styles.loginRecoverLink}
              >
                @fbmpay_manager
              </a>
            </p>
          </div>
          <Button className={styles.loginContainerRegistrationButton}>
            <a href="https://t.me/v_astanin" target="_blank">
              Contact <TelegramIcon />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
