import { useEffect, useState } from "react";
import { Button, Group, Box } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import Table from "../../../components/Table/Table";
import WalletTopUp from "../../../icons/WalletTopUp";
import { useQuery } from "@tanstack/react-query";
import { getAccounts } from "../../../api/getAccounts";
import Rename from "../../../icons/Rename";
import styles from "./styles.module.scss";
import ModalIssueAccount from "../../Modals/ModalIssueAccount/ModalIssueAccount";
import TopUpModal from "../../Modals/TopUpModal/TopUpModal";

const AllAccountsTable = () => {
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["accounts"],
    queryFn: getAccounts,
  });
  const [tableData, setTableData] = useState([]);
  const [issueModal, setIssueModal] = useState(false);
  const [topUpModal, setTopUpModal] = useState(false);

  useEffect(() => {
    if (data && data.data) {
      const formattedData = data.data.map((account) => ({
        accountName: account.name || "N/A",
        iban: account.iban || "N/A",
        users: account.usersCount || 0,
        notification:
          account.availableAmount && account.availableAmount > 0
            ? "-"
            : "Top-Up your balance",
        availableBalance: account.availableAmount || 0,
      }));
      setTableData(formattedData);
    } else {
      console.log("no data");
    }
  }, [data]);

  const columns = [
    {
      accessorKey: "accountName",
      header: "Account’s name / IBAN",
      enableSorting: false,
      Cell: ({ row }) => (
        <div className={styles.tableAccountName}>
          <div className={styles.tableAccountsNameTitle}>
            {row.original.accountName}
            <button>
              <Rename />
            </button>
          </div>
          <div className={`"primaryText", ${styles.tableAccountsNameIban}`}>
            {row.original.iban}
          </div>
        </div>
      ),
    },
    {
      accessorKey: "users",
      header: "Users",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "notification",
      header: "Notification",
      enableSorting: true,
      enableColumnFilter: false,
    },
    {
      accessorKey: "availableBalance",
      header: "Available Balance",
      enableSorting: true,
      Cell: ({ cell }) =>
        cell.getValue().toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
      filterVariant: "range-slider",
      filterFn: "betweenInclusive",
      mantineFilterRangeSliderProps: {
        max: 50_000,
        min: 0,
        step: 250,
        label: (value) =>
          value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <Group>
          <Button
            style={{
              height: "24px",
              background:
                "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",

              color: "#fff",
            }}
            size="xs"
            onClick={() => console.log("Edit", row.original)}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Top up</p> <WalletTopUp />
            </div>
          </Button>
          <Button
            style={{
              background: "transparent",
              color: "#232323",
              border: "1px solid #232323",
              height: "24px",
            }}
            size="xs"
            onClick={() => console.log("Edit", row.original)}
          >
            Transfer
          </Button>
        </Group>
      ),
    },
  ];

  return (
    <Box
      style={{ borderRadius: "8px", overflow: "hidden", background: "#fff" }}
      styles={{
        maxWidth: "calc(100% - 40px)",
        minWidth: "700px",
      }}
    >
      {tableData && (
        <Table
          columns={columns}
          data={tableData}
          enablePagination={true}
          initialState={{ pageSize: 5 }}
          enableColumnActions={false}
          enableColumnFilters={true}
          enableGlobalFilter={false}
          enableHiding={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          renderTopToolbarCustomActions={() => (
            <Group
              position="apart"
              style={{
                justifyContent: "start",
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>
                <h3>ALL ACCOUNTS</h3>
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                <Button
                  rightIcon={<IconPlus />}
                  style={{
                    background:
                      "linear-gradient(90deg, #34d058 0%, #34b4d0 100%)",
                    color: "#fff",
                  }}
                  onClick={() => setIssueModal(true)}
                >
                  Issue an account
                </Button>
                <Button
                  rightIcon={<WalletTopUp />}
                  style={{
                    background:
                      "linear-gradient(90deg, #34d058 0%, #34b4d0 100%)",
                    color: "#fff",
                  }}
                  onClick={() => setTopUpModal(true)}
                >
                  Top up balance
                </Button>
              </div>
            </Group>
          )}
          mantineTableBodyCellProps={{
            style: {
              padding: "16px",
            },
          }}
          mantineTableProps={{
            striped: true,
            highlightOnHover: true,
          }}
          mantineTableHeaderCellProps={{
            style: {
              paddingLeft: "24px",
              paddingRight: "24px",
            },
          }}
        />
      )}
      {issueModal && <ModalIssueAccount setIssueModal={setIssueModal} />}
      {topUpModal && tableData && (
        <TopUpModal tableData={tableData} setTopUpModal={setTopUpModal} />
      )}
    </Box>
  );
};

export default AllAccountsTable;
