import { useEffect, useState } from "react";
import {
  Checkbox,
  Button,
  Popover,
  Group,
  Box,
  Text,
  ActionIcon,
} from "@mantine/core";
import { getAccounts } from "../../api/getAccounts";
import { IconX } from "@tabler/icons-react";

export const AccountSelect = ({ selectedAccounts, setSelectedAccounts }) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAccounts();
        setAccounts(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleAccountSelection = (account) => {
    setSelectedAccounts((current) =>
      current.find((acc) => acc.ID === account.ID)
        ? current
        : [...current, account]
    );
  };

  const handleSelectAll = () => {
    if (selectedAccounts.length === accounts.length) {
      setSelectedAccounts([]);
    } else {
      setSelectedAccounts(accounts);
    }
  };

  const handleRemoveAccount = (accountId) => {
    setSelectedAccounts((current) =>
      current.filter((account) => account.ID !== accountId)
    );
  };

  return (
    <Box>
      <Popover
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
        position="bottom-start"
        withArrow
        width={300}
        offset={10}
        trapFocus
      >
        <Popover.Target>
          <Button
            onClick={() => setPopoverOpened((o) => !o)}
            style={{
              color: "black",
              padding: "10px 20px",
              background: "transparent",
              border: "1px solid #23232340",
              borderRadius: "8px",
              marginBottom: selectedAccounts.length > 0 ? "20px" : "34px",
            }}
          >
            Add account +
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <Checkbox
            label="All"
            checked={selectedAccounts.length === accounts.length}
            onChange={handleSelectAll}
            mb="sm"
          />

          {accounts.map((account, idx) => (
            <Checkbox
              key={`${account.ID}+${idx}`}
              label={`${account.name || "Unnamed"}, ${
                account.iban || "No IBAN"
              }, ${account.availableAmount || 0} EUR`}
              checked={selectedAccounts.some((acc) => acc.ID === account.ID)}
              onChange={() => handleAccountSelection(account)}
              mb="xs"
            />
          ))}
        </Popover.Dropdown>
      </Popover>

      {selectedAccounts.map((account, idx) => (
        <div
          style={{ marginBottom: "36px", width: "fit-content" }}
          key={`account-${account.ID}+${idx}`}
        >
          <Box
            style={{
              backgroundColor: "#f0f0f0",
              borderRadius: "5px",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Text size="sm" style={{ color: "#AAAAAA" }}>{`${
              account.name || "Unnamed"
            }, ${account.iban || "No IBAN"}, ${
              account.availableAmount || 0
            } EUR`}</Text>
            <ActionIcon
              size="xs"
              color="red"
              onClick={() => handleRemoveAccount(account.ID)}
            >
              <IconX size={14} style={{ color: "#AAAAAA" }} />
            </ActionIcon>
          </Box>
        </div>
      ))}
    </Box>
  );
};
