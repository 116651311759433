import tg from "./images/TG.svg";
import agencyAccountsIcon from "./images/agencyAccounts.png";
import landingsIcon from "./images/landings.png";
import videoCreativesIcon from "./images/videoCreatives.png";
import GamblingsImg from "./images/gamblings.png";
import boostSocialMediaIcon from "./images/boostSocialMedia.png";
import shopSellingIcon from "./images/shop.png";
import videoReviewsIcon from "./images/videoReviews.png";
import redMediaIcon from "./images/redMedia.png";
import ecosystemTgIcon from "./images/ecosystemTg.png";
import tgChatIcon from "./images/tgChat.png";
import iosAppsForRent from "./images/iosAppsForRent.png";
import appsForRent from "./images/appsForRent.png";

import styles from "./styles.module.scss";

export const serviceData = [
  {
    imgSrc: agencyAccountsIcon,
    alt: "Agency accounts",
    title: "Agency accounts",
    text: (
      <>
        Official Facebook, Google, Tik Tok, Taboola agency accounts with credit
        line.{" "}
        <span className={styles.boldText}>
          We allow to put traffic on WH / GH / BH.
        </span>{" "}
        Fast issuing, lowest commission.
      </>
    ),
    span: "@fbm.agency",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://t.me/fbm_agency",
    buttonLink: "/accounts/all_accounts",
  },
  {
    imgSrc: landingsIcon,
    alt: "Landings and pre-landings",
    title: "Landings and pre-landings",
    text: (
      <>
        Landing pages of any complexity for the desired offer and native selling
        pre-landings with stories on ‘em.{" "}
        <span className={styles.boldText}>
          In our portfolio more than 300 sites done.
        </span>
      </>
    ),
    span: "@fbm.landings",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_landings",
    buttonLink: "https://fbm.red/prelendi-dlya-arbitrazha-trafika/",
  },
  {
    imgSrc: videoCreativesIcon,
    alt: "Static and video creatives",
    title: "Static and video creatives",
    text: (
      <>
        The best service for creating static banners and video ads on the
        market. We made over <span className={styles.boldText}>3000 creos</span>{" "}
        for FB, Google, TT, etc,{" "}
        <span className={styles.boldText}>
          so you won’t have any troubles with moderation.
        </span>{" "}
      </>
    ),
    span: "@fbm.creo",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_creo",
    buttonLink: "https://fbm.red/kreativi-dlya-reklami/",
  },
  {
    imgSrc: GamblingsImg,
    alt: "",
    title: "Gambling adaptations",
    text: (
      <>
        Service for selling ready-made gambling creatives.{" "}
        <span className={styles.boldText}>
          Get top creatives quickly and inexpensively.
        </span>
      </>
    ),
    span: "@fbm_gambling",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_gambling",
    buttonLink: "https://t.me/fbm_gambling",
  },
  {
    imgSrc: iosAppsForRent,
    alt: "",
    title: "iOS apps for rent",
    text: (
      <>
        Rent iOS applications for Gambling and Betting. Onelink and direct
        sharing are available.{" "}
        <span className={styles.boldText}>From $0.08 per install.</span>
      </>
    ),
    span: "@fbm_apps",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://t.me/fbm_apps",
    buttonLink: "https://fbm.red/apps/",
  },
  {
    imgSrc: appsForRent,
    alt: "",
    title: "PWA apps for rent",
    text: (
      <>
        Create PWA apps quickly, ready-made designs, importing apps from Google
        Play, set pushes, postbacks and much more.{" "}
        <span className={styles.boldText}> From $0.05 per install.</span>
      </>
    ),
    span: "@fbm_apps",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://t.me/fbm_apps",
    buttonLink: "https://fbm.red/pwa-prilozhenie/",
  },
  {
    imgSrc: shopSellingIcon,
    alt: "",
    title: "Shop selling consumables",
    text: (
      <>
        Trusted & farmed FB accounts, proxy and a lot more things{" "}
        <span className={styles.boldText}>to buy traffic in FB easily.</span>
      </>
    ),
    span: "@fbm_ibot",
    icon: tg,
    buttonText: "Buy Now",
    aLink: "https://t.me/fbm_ibot",
    buttonLink: "https://t.me/fbm_ibot",
  },
  {
    imgSrc: videoReviewsIcon,
    alt: "Video reviews for your needs",
    title: "Video reviews for your needs",
    text: (
      <>
        With the help of this service you can order{" "}
        <span className={styles.boldText}>
          video reviews with our actors for any project and niche.
        </span>
      </>
    ),
    span: "@fbm_reviews",
    icon: tg,
    buttonText: "Order Now",
    aLink: "https://t.me/fbm_reviews",
    buttonLink: "https://t.me/fbm_reviews",
  },
  {
    imgSrc: boostSocialMediaIcon,
    alt: "",
    title: "Boost for social media",
    text: (
      <>
        We are working with such social medias like Facebook, Instagram, Tik Tok
        and so on.{" "}
        <span className={styles.boldText}>Become more popular with us.</span>
      </>
    ),
    span: "@fbm.boost",
    icon: tg,
    buttonText: "Start Now",
    aLink: "https://fbm-boost.com",
    buttonLink: "https://fbm-boost.com",
  },

  {
    imgSrc: redMediaIcon,
    alt: "[FBM] RED MEDIA",
    title: "[FBM] RED MEDIA",
    text: (
      <>
        <span className={styles.boldText}>
          Official media from [FBM] Ecosystem.
        </span>{" "}
        There we publish articles on a variety of topics: bug fixes, life hacks,
        reviews of new products and much more.
      </>
    ),
    span: "@fbm.red",
    icon: tg,
    buttonText: "Go to website",
    aLink: "https://t.me/fbm_red",
    buttonLink: "https://t.me/fbm_red",
  },
  {
    imgSrc: ecosystemTgIcon,
    alt: "[FBM] Ecosystem TG",
    title: "[FBM] Ecosystem TG",
    text: (
      <>
        Our telegram channel where we are posting{" "}
        <span className={styles.boldText}>ONLY useful information</span>: ways
        to escape of bugs, to make your ads more profit and so on.{" "}
      </>
    ),
    span: "@fbm_red",
    icon: tg,
    buttonText: "Subscribe",
    aLink: "https://t.me/fbm_red",
    buttonLink: "https://t.me/fbm_red",
  },
  {
    imgSrc: tgChatIcon,
    alt: "[FBM] Telegram chat",
    title: "[FBM] Telegram chat",
    text: (
      <>
        In our free private chat{" "}
        <span className={styles.boldText}>anyone can receive answer</span> on
        their question about traffic, Facebook and all this stuff.{" "}
      </>
    ),
    span: "@fbm_ichat",
    icon: tg,
    buttonText: "Join",
    aLink: "https://t.me/fbm_ichat",
    buttonLink: "https://t.me/fbm_ichat",
  },
];
