const ReloadIcon = ({ className, style, onClick }) => {
  return (
    <>
      <svg
        onClick={onClick}
        className={className}
        style={style}
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style>
          {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .animate-spin {
          animation: spin 1s linear infinite;
        }
      `}
        </style>
        <path
          d="M13.9587 5.151C15.9404 5.03627 17.9072 5.55418 19.5754 6.62997C20.5944 7.28711 21.4697 8.13165 22.1592 9.11167H18.7292C17.895 9.11167 17.2188 9.78791 17.2188 10.6221C17.2188 11.4563 17.895 12.1326 18.7292 12.1326H25.3751C25.7756 12.1326 26.1599 11.9734 26.4431 11.6901C26.7263 11.4069 26.8855 11.0227 26.8855 10.6221V3.66977C26.8855 2.83559 26.2093 2.15936 25.3751 2.15936C24.5408 2.15936 23.8646 2.83559 23.8646 3.66977V6.39418C23.0992 5.50985 22.2077 4.733 21.2126 4.09127C19.0063 2.66845 16.405 1.98347 13.7841 2.13522C11.1633 2.28696 8.65843 3.26757 6.63111 4.9355C4.60379 6.60344 3.1589 8.87241 2.505 11.4149C2.29722 12.2228 2.7837 13.0462 3.5916 13.254C4.39948 13.4617 5.22285 12.9752 5.43062 12.1674C5.92505 10.245 7.01752 8.52941 8.55037 7.26829C10.0832 6.00716 11.9771 5.26573 13.9587 5.151Z"
          fill="#21A038"
        />
        <path
          d="M9.42018 22.3673C11.0863 23.443 13.051 23.9621 15.0311 23.8495C17.0112 23.737 18.9044 22.9987 20.4379 21.7411C21.9715 20.4834 23.0662 18.7713 23.5642 16.8517C23.7736 16.0443 24.5981 15.5595 25.4055 15.769C26.2129 15.9784 26.6977 16.8028 26.4882 17.6103C25.8295 20.1492 24.3818 22.4135 22.3535 24.0769C20.3253 25.7402 17.8214 26.7166 15.2026 26.8655C12.5837 27.0144 9.98528 26.3279 7.78164 24.9051C6.78876 24.2641 5.89913 23.4884 5.13509 22.6057V25.3302C5.13509 26.1644 4.45886 26.8406 3.62467 26.8406C2.79049 26.8406 2.11426 26.1644 2.11426 25.3302V18.3779C2.11426 17.9772 2.2734 17.5931 2.55665 17.3099C2.83991 17.0265 3.22409 16.8675 3.62467 16.8675H10.2705C11.1047 16.8675 11.7809 17.5437 11.7809 18.3779C11.7809 19.212 11.1047 19.8883 10.2705 19.8883H6.84054C7.52908 20.867 8.40296 21.7105 9.42018 22.3673Z"
          fill="#21A038"
        />
      </svg>
    </>
  );
};

export default ReloadIcon;
