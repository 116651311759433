import { Button } from "../../../components/Button/Button";
import Modal from "../Modal";
import styles from "./styles.module.scss";

export const ModalsInteractive = ({
  activeModal,
  modalData,
  onSubmit,
  onCancel,
}) => {
  if (!activeModal) return null;

  const modalsContent = {
    blockUser: {
      title: "blockUser", //logic key modal action
      subTitle: "Block user",
      message: `User ${modalData?.userName} (${modalData?.userEmail}) will be blocked and forcibly logged out from their account.`,
      questions: "Are you sure you want to block this user?",
      confirmLabel: "Block",
    },
    unblockUser: {
      title: "unblockUser", //logic key modal action
      subTitle: "Unblock User",
      message: `User ${modalData?.userName} (${modalData?.userEmail}) will be unblocked and able to log into their account again.`,
      questions: "Are you sure you want to unblock this user?",
      confirmLabel: "Unblock",
    },
    removeAccount: {
      title: "removeAccount", //logic key modal action
      subTitle: "Remove account",
      message: (
        <>
          User {modalData?.userName || ""} ({modalData?.userEmail || ""}) will
          lose access{" "}
          <strong>
            to this account: "{modalData.accountName || ""}" and all its cards.
          </strong>
        </>
      ),
      questions: "Are you sure you want to remove this account?",
      confirmLabel: "Remove",
    },
    grantAccount: {
      title: "grantAccount", //logic key modal action
      subTitle: "Grant access",
      message: `User ${modalData?.userName} (${modalData?.userEmail}) will gain access to the account "${modalData?.accountName}".`,
      questions: "Are you sure you want to grant access?",
      confirmLabel: "Grant Access",
    },
  };

  const { title, subTitle, message, confirmLabel, questions } =
    modalsContent[activeModal];

  return (
    <Modal opened onClose={onCancel} title={title}>
      <div className={styles.contentModal}>
        <div className={styles.containerText}>
          <p className="H1">{subTitle}</p>
          <div className={styles.containerSubtitleText}>
            <span className={styles.subTitle}>{message}</span>
            <span>{questions}</span>
          </div>
        </div>

        <div className={styles.containerButtons}>
          <Button
            onClick={() => onSubmit(activeModal, modalData)}
            className={styles.activeBtn}
          >
            {confirmLabel}
          </Button>

          <Button
            variant="outline"
            onClick={onCancel}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
