import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

// export const getAllTeamMembers = async () => {
//   const result = await axiosAuth.get(API_PATHS.ALL_TEAM_MEMBERS);
//   return result.data;
// };
export const getAllTeamMembers = async ({ page, perPage }) => {
  const response = await axiosAuth.get(API_PATHS.ALL_TEAM_MEMBERS, {
    params: {
      page: page,
      per_page: perPage,
    },
  });
  return response.data;
};

export const removeUserAccount = async (id, userId) => {
  return axiosAuth.delete(`/api/accounts/${id}/user/${userId}`);
};
