import React from "react";
import styles from "./styles.module.scss";
import QestionIcon from "../../../icons/QestionIcon";
import { getColor } from "../../../utils/getColorRateItem";
import Skeleton from "../../Skeleton/Skeleton";

const DeclineRate = ({ data, isFetching, isLoading }) => {
  const dashBoardRatesData = data?.data?.data || {};

  return (
    <div className={styles.containerRate}>
      <div className={styles.containerTitle}>
        <p>TOTAL DECLINE RATE</p>
        <QestionIcon />
      </div>

      {isFetching || isLoading ? (
        <Skeleton className={styles.widgetSkeleton} />
      ) : (
        <div className={styles.wrapperContainerProcents}>
          <div
            className={styles.containerProcents}
            style={{
              border: `2px solid ${getColor(
                dashBoardRatesData?.totalDeclineRateWeek?.value
              )}`,
            }}
          >
            <span>{dashBoardRatesData.totalDeclineRateWeek?.value || 0}% </span>
            in last 7 days
          </div>
          <div
            className={styles.containerProcents}
            style={{
              border: `2px solid ${getColor(
                dashBoardRatesData.totalDeclineRateMonth?.value
              )}`,
            }}
          >
            <span>{dashBoardRatesData.totalDeclineRateMonth?.value}% </span>in
            last 30 days
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DeclineRate);
