const DashboardIcon = ({ fill = "#232323" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.625 2.25C3.34682 2.25 1.5 4.09682 1.5 6.375V14.625C1.5 16.9032 3.34682 18.75 5.625 18.75H18.375C20.6532 18.75 22.5 16.9032 22.5 14.625V6.375C22.5 4.09682 20.6532 2.25 18.375 2.25H5.625ZM3 6.375C3 4.92525 4.17525 3.75 5.625 3.75H18.375C19.8248 3.75 21 4.92525 21 6.375V14.625C21 16.0747 19.8248 17.25 18.375 17.25H5.625C4.17525 17.25 3 16.0747 3 14.625V6.375Z"
        fill={fill}
      />
      <path
        d="M6 20.25C5.58579 20.25 5.25 20.5858 5.25 21C5.25 21.4142 5.58579 21.75 6 21.75H18C18.4142 21.75 18.75 21.4142 18.75 21C18.75 20.5858 18.4142 20.25 18 20.25H6Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardIcon;
