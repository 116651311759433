import { useState } from "react";
import styles from "./styles.module.scss";
import Pagination from "../../components/pagination/Pagination";
import AllAccounts from "../../modules/Accounts/AllAccounts";
import ItemBlockInfo from "../../components/itemBlockInfo/ItemBlockInfo";
import AllAccountsTable from "../../modules/Tables/AllAccounts/AllAccountsTable";

const Accounts = () => {
  const [activeTab, setActiveTab] = useState(0);

  const items = [
    { title: "DEPOSIT SUM", titleColored: "TOTAL" },
    { title: "AMOUNT CREDITED", titleColored: "TOTAL" },
    { title: "FEE AMOUNT", titleColored: "TOTAL" },
    { title: "OF ALL DEPOSITS", titleColored: "NUMBER" },
  ];

  const tabs = ["All Accounts", "Balance History"];
  return (
    <div className={styles.accountsPage}>
      <div className={styles.accountPageHeader}>
        <h1>Accounts</h1>
        <Pagination
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.accountsBlockInfoContainer}>
        {items.map((item, index) => (
          <ItemBlockInfo
            key={index}
            title={item.title}
            titleColored={item.titleColored}
            additContent={<div className="H1">$0.00</div>}
            linkTitle="Details"
            href="/"
            highlightPosition="first"
          />
        ))}
      </div>

      <div>
        {activeTab === 0 && (
          <div className={styles.tableContainer}>
            <AllAccountsTable />
          </div>
        )}
        {activeTab === 1 && <div> Balance History</div>}
      </div>
    </div>
  );
};

export default Accounts;
