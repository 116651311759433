import TeamManagementTable from "../../modules/Tables/TeamManagementTable";
import styles from "./styles.module.scss";

const TeamManagement = () => {
  return (
    <div className={styles.mainWrapper}>
      <p className="H1">Team Management</p>
      <div className={styles.containerTable}>
        <TeamManagementTable />
      </div>
    </div>
  );
};

export default TeamManagement;
