import { useCallback, useEffect, useState } from "react";
import { SECONDS_TO_TESEND_2FA_CODE } from "../constants/constants";

const useTimeOutLine = () => {
  const [timeLeft, setTimeLeft] = useState(0); // Start with 0 to prevent immediate timer start
  const [isCodeTimeout, setIsCodeTimeout] = useState(true); // Initially, code is timed out

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      setIsCodeTimeout(false); // Code is not timed out when timer is running
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsCodeTimeout(true); // Code has timed out
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  const startTimer = useCallback(() => {
    setTimeLeft(SECONDS_TO_TESEND_2FA_CODE);
  }, []);

  return { timeLeft, isCodeTimeout, startTimer };
};

export default useTimeOutLine;
