const MetaPayIcon = ({ fill = "#232323" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25098 5.25C5.25098 4.83579 4.91519 4.5 4.50098 4.5C4.08676 4.5 3.75098 4.83579 3.75098 5.25V18.75C3.75098 19.1642 4.08676 19.5 4.50098 19.5C4.91519 19.5 5.25098 19.1642 5.25098 18.75V5.25Z"
          fill={fill}
        />
        <path
          d="M7.5 4.5C7.91421 4.5 8.25 4.83579 8.25 5.25V16.4357C8.25 16.8499 7.91421 17.1857 7.5 17.1857C7.08578 17.1857 6.75 16.8499 6.75 16.4357V5.25C6.75 4.83579 7.08578 4.5 7.5 4.5Z"
          fill={fill}
        />
        <path
          d="M11.25 5.25C11.25 4.83579 10.9142 4.5 10.5 4.5C10.0858 4.5 9.75 4.83579 9.75 5.25V16.4357C9.75 16.8499 10.0858 17.1857 10.5 17.1857C10.9142 17.1857 11.25 16.8499 11.25 16.4357V5.25Z"
          fill={fill}
        />
        <path
          d="M13.5 4.5C13.9142 4.5 14.25 4.83579 14.25 5.25V18.75C14.25 19.1642 13.9142 19.5 13.5 19.5C13.0858 19.5 12.75 19.1642 12.75 18.75V5.25C12.75 4.83579 13.0858 4.5 13.5 4.5Z"
          fill={fill}
        />
        <path
          d="M17.25 5.25C17.25 4.83579 16.9142 4.5 16.5 4.5C16.0858 4.5 15.75 4.83579 15.75 5.25V16.4357C15.75 16.8499 16.0858 17.1857 16.5 17.1857C16.9142 17.1857 17.25 16.8499 17.25 16.4357V5.25Z"
          fill={fill}
        />
        <path
          d="M20.25 5.25C20.25 4.83579 19.9142 4.5 19.5 4.5C19.0858 4.5 18.75 4.83579 18.75 5.25V18.75C18.75 19.1642 19.0858 19.5 19.5 19.5C19.9142 19.5 20.25 19.1642 20.25 18.75V5.25Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default MetaPayIcon;
