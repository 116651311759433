import { useState } from "react";
import ProfileSettings from "../../modules/ProfileSettings/ProfileSettings";
import LimitAndConditions from "../../modules/LimitAndConditions/LimitAndConditions";
import Pagination from "../../components/pagination/Pagination";
import styles from "./styles.module.scss";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Settings", "Limit and Conditions"];
  const components = [<ProfileSettings />, <LimitAndConditions />];

  return (
    <div className={styles.profilePage}>
      <div className={styles.profileTitle}>
        <h1>Profile</h1>{" "}
        <Pagination
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>{components[activeTab]}</div>
    </div>
  );
};

export default Profile;
