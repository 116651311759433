import { useQueries } from "@tanstack/react-query";
import { createContext, useMemo, useState } from "react";
import { getUserDataa } from "../api/userApi";
import useLogoutUser from "../hooks/useLogoutUser";
import { getWidgetsValues } from "../api/dashboardApi";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [email, setEmail] = useState("");

  const result = useQueries({
    queries: [
      {
        queryKey: ["userDataInfo"],
        queryFn: getUserDataa,
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        enabled: !!token,
      },
      {
        queryKey: ["infoPricesTotal"],
        queryFn: getWidgetsValues,
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        enabled: !!token,
      },
    ],
  });

  const [userDataInfo, infoPricesTotal] = result;

  const {
    data: userDataDetailed,
    isFetching: userDataFetching,
    isLoading: userDataIsLoading,
    error: errorUserData,
  } = userDataInfo;

  const {
    data: infoPricesTotalData,
    isLoading: infoPricesTotalLoading,
    isFetching: infoPricesTotalFetching,
  } = infoPricesTotal;

  useLogoutUser({ token, setToken });

  const userData = userDataDetailed?.data?.data;
  const totalPricesAccount = infoPricesTotalData?.data;

  const authContextValue = useMemo(
    () => ({
      token,
      setToken,
      userData,
      userDataFetching,
      userDataIsLoading,
      setEmail,
      email,
      errorUserData,
      totalPricesAccount,
      infoPricesTotalLoading,
    }),
    [
      token,
      userData,
      userDataFetching,
      userDataIsLoading,
      email,
      totalPricesAccount,
      errorUserData,
      infoPricesTotalLoading,
    ]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
