import React from "react";
import styles from "./styles.module.scss";

const FooterMainLayout = () => {
  return (
    <div className={styles.containerFooter}>
      <p>
        Copyright © 2024 <span className={styles.textFirmName}>[FBM]PAY</span>.
        All rights reserved
      </p>
    </div>
  );
};

export default FooterMainLayout;
