import Accounts from "./accounts/Accounts";
import Cards from "./cards/Cards";
import { PAGE_ROUTES } from "./constants";
import Dashboard from "./dashboard/Dashboard";
import FBMServices from "./fbm-services/FBMServices";
import TwoFaPage from "./login/2fa/TwoFaPage";
import { Login } from "./login/Login";
import MetapayAndThreeDs from "./metapay-and-3ds/MetapayAndThreeDs";
import NotFounded from "./not-found/NotFounded";
import RecoverPassword from "./recoverPassword/RecoverPassword";
import Payments from "./payments/Payments";
import Profile from "./profile/Profile";
import TeamManagement from "./team-management/TeamManagement";
import ResetPassword from "./resetPassword/ResetPassword";
import ProfileChange from "./profile/change/ProfileChange";
import ChangeProfilePassword from "./profile/change-password/ChangeProfilePassword";
import News from "./news/News";
import NewsArticle from "./newsArticle/NewsArticle";

export const authRoutes = [
  { path: PAGE_ROUTES.DASHBOARD, Element: Dashboard },
  { path: PAGE_ROUTES.ACCOUNTS, Element: Accounts },
  { path: PAGE_ROUTES.CARDS, Element: Cards },
  {
    path: PAGE_ROUTES.TEAM_MANAGEMENT,
    Element: TeamManagement,
    allowedRoles: ["Team Lead", "Owner", "Admin"],
  },
  { path: PAGE_ROUTES.PAYMENTS, Element: Payments },
  { path: PAGE_ROUTES.METAPAY_AND_3DS, Element: MetapayAndThreeDs },
  { path: PAGE_ROUTES.FBM_SERVICES, Element: FBMServices },
  { path: PAGE_ROUTES.DASHBOARD, Element: Dashboard },
  { path: PAGE_ROUTES.PROFILE, Element: Profile },
  { path: PAGE_ROUTES.PROFILE_CHANGE, Element: ProfileChange },
  { path: PAGE_ROUTES.PROFILE_PASSWORD_CHANGE, Element: ChangeProfilePassword },
  { path: PAGE_ROUTES.NEWS, Element: News },
  { path: PAGE_ROUTES.NEWS_ARTICLE, Element: NewsArticle },
];

export const publicRoutes = [
  { path: PAGE_ROUTES.LOGIN, Element: Login },
  { path: PAGE_ROUTES.TWO_FA_AFTER_LOGIN, Element: TwoFaPage },
  { path: PAGE_ROUTES.RECOVER_PASSWORD, Element: RecoverPassword },
  { path: PAGE_ROUTES.RESET_PASSWORD, Element: ResetPassword },
  { path: PAGE_ROUTES.NOT_FOUND, Element: NotFounded },
];
