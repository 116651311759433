export const API_PATHS = {
  USER_DATA: "/api/auth/me",
  CHANGE_USER_DATA: "/api/users",
  ACCOUNTS: "/api/accounts",
  FUND_ACCOUNT: "api/accounts/fund",
  LOGIN: "/api/auth/login",
  CHECK_2FA_CODE: "/api/auth/check-code",
  SEND_2FA_CODE_AGAIN: "/api/auth/code",
  LOGOUT_USER: "/api/auth/logout",
  CHANGE_PASSWORD: "/api/users/change-password",
  SEND_RECOVER_CODE: "/api/auth/reset-password",
  ALL_NEWS: "/api/news",
  DASHBOARD_WIDGETS_VALUES: "/api/widgets/dashboard",
  ALL_TEAM_MEMBERS: "/api/users",
  CREATE_USER: "/api/users/create",
  CREATE_ACCOUNT: "api/accounts",
};
