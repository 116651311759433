import React from "react";
import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import style from "./styles.module.scss";

// Footer manuBar
const Footer = () => {
  return (
    <footer className={style.footer}>
      <Link to={PAGE_ROUTES.NEWS}>Latest news</Link>
      <Link to={PAGE_ROUTES.YOUR_MANAGER}>Your manager</Link>
      <div className={style.containerTgChannel}>
        <Link to={PAGE_ROUTES.TG_CHANNEL}>Our TG channel</Link>
        <Link className={style.subscripteLink} to={PAGE_ROUTES.TG_CHANNEL}>
          Subscribe
        </Link>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
