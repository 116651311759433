import { Link, useNavigate } from "react-router-dom";
import TwoFaIcon from "../../icons/TwoFaIcon";
import { Button } from "../../components/Button/Button";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { getUserData, logoutUser } from "../../api/userApi";
import { PAGE_ROUTES } from "../../pages/constants";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthQuery } from "../../hooks/useAuthQuery";

const ProfileSettings = () => {
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, isError, error } = useAuthQuery({
    queryKey: ["userProfileSettings"],
    queryFn: getUserData,
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
  const navigate = useNavigate();
  const profileData = data?.data;

  if (isError) {
    return <div>{error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem("token");
      queryClient.invalidateQueries(["userProfileSettings"]);
      navigate(PAGE_ROUTES.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.profileSettigns}>
      <div>
        <div className={clsx(styles.flexRow)}>
          <h2>Status</h2>
          <span>Active</span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Registration Date</h2>
          <DateFormatter date={profileData?.registred_at} loading={isLoading} />
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Name</h2>
          <div className={styles.profileChangeContainer}>
            {isLoading || isFetching ? (
              <div className={styles.textSkeleton}></div>
            ) : (
              <span>{profileData?.name}</span>
            )}
            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>E-mail</h2>
          {isLoading || isFetching ? (
            <div className={styles.profileChangeContainer}>
              <div className={styles.textSkeleton}></div>
            </div>
          ) : (
            <span>{profileData?.email}</span>
          )}
        </div>

        <div className={clsx(styles.flexRow)}>
          <h2>Telegram Chat</h2>
          <div className={styles.profileChangeContainer}>
            {isLoading || isFetching ? (
              <div className={styles.textSkeleton}></div>
            ) : (
              <span>{profileData?.telegram}</span>
            )}

            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>

        <div className={clsx(styles.flexRow)}>
          <h2>Password</h2>
          <div className={styles.profileChangeContainer}>
            <span>******</span>
            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_PASSWORD_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>

        <div className={clsx(styles.flexRow)}>
          <div>
            <h2>Two-factor authentication*</h2>
            <span>
              We do not allow you to turn it off for security reasons.
            </span>
          </div>
          <div>
            <TwoFaIcon />
          </div>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Logout from account</h2>

          <Button onClick={handleLogout} className={styles.profileChangeLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
