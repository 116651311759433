const SuccessIcon = () => {
  return (
    <>
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 7L8.5 14L20.5 2" stroke="white" stroke-width="3" />
      </svg>
    </>
  );
};

export default SuccessIcon;
