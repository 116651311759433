const ArrowLeft = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5559 19.2563C10.8976 19.598 10.8976 20.152 10.5559 20.4937C10.2142 20.8354 9.66015 20.8354 9.31844 20.4937L3.03443 14.2097C1.81404 12.9893 1.81404 11.0107 3.03443 9.79029L9.31844 3.50628C9.66015 3.16457 10.2142 3.16457 10.5559 3.50628C10.8976 3.84799 10.8976 4.40201 10.5559 4.74372L4.27187 11.0277C4.24053 11.0591 4.21103 11.0915 4.18336 11.125H20.9996C21.4829 11.125 21.8746 11.5167 21.8746 12C21.8746 12.4832 21.4829 12.875 20.9996 12.875H4.18336C4.21103 12.9085 4.24053 12.9409 4.27187 12.9723L10.5559 19.2563Z"
          fill="#21a038"
        />
      </svg>
    </>
  );
};

export default ArrowLeft;
