const ProfileIcon = ({ fill = "#232323" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.625C9.92895 5.625 8.25 7.30394 8.25 9.375C8.25 11.4461 9.92895 13.125 12 13.125C14.0711 13.125 15.75 11.4461 15.75 9.375C15.75 7.30394 14.0711 5.625 12 5.625ZM9.75 9.375C9.75 8.13232 10.7573 7.125 12 7.125C13.2427 7.125 14.25 8.13232 14.25 9.375C14.25 10.6177 13.2427 11.625 12 11.625C10.7573 11.625 9.75 10.6177 9.75 9.375Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 14.1002 4.53474 16.017 5.827 17.4734C5.9548 17.0453 6.14074 16.6627 6.37758 16.3235C6.85546 15.6393 7.50502 15.1828 8.18347 14.8786C9.5145 14.2818 11.0617 14.2266 12 14.2266C12.9383 14.2266 14.4855 14.2818 15.8165 14.8786C16.495 15.1828 17.1445 15.6393 17.6224 16.3235C17.8592 16.6627 18.0452 17.0453 18.173 17.4734C19.4653 16.017 20.25 14.1002 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM12 20.25C10.1802 20.25 8.49803 19.6608 7.13384 18.6628C7.17536 18.0117 7.35938 17.5375 7.60732 17.1824C7.89367 16.7724 8.30175 16.4694 8.7972 16.2472C9.8139 15.7914 11.0792 15.7266 12 15.7266C12.9208 15.7266 14.1861 15.7914 15.2028 16.2472C15.6982 16.4694 16.1063 16.7724 16.3927 17.1824C16.6406 17.5375 16.8247 18.0117 16.8661 18.6628C15.502 19.6608 13.8198 20.25 12 20.25Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ProfileIcon;
