const QestionIcon = () => {
  return (
    <>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3701 12.6562C10.3701 13.0446 10.0553 13.3594 9.66699 13.3594C9.27864 13.3594 8.96387 13.0446 8.96387 12.6562C8.96387 12.2679 9.27864 11.9531 9.66699 11.9531C10.0553 11.9531 10.3701 12.2679 10.3701 12.6562Z"
          fill="#21A038"
        />
        <path
          d="M7.97949 7.59375C7.97949 6.88534 8.6622 6.1875 9.66727 6.1875C10.6723 6.1875 11.355 6.88534 11.355 7.59375C11.355 7.93389 11.2579 8.13836 11.1318 8.29136C10.9894 8.46405 10.7855 8.60226 10.5074 8.76572C10.4724 8.78625 10.4352 8.80774 10.3964 8.83018C10.1619 8.96546 9.86662 9.1359 9.63617 9.34982C9.33298 9.63124 9.10477 10.0146 9.10477 10.5469C9.10477 10.8575 9.3566 11.1094 9.66727 11.1094C9.97788 11.1094 10.2298 10.8575 10.2298 10.5469C10.2298 10.384 10.2828 10.2846 10.4016 10.1743C10.5314 10.0538 10.6991 9.95619 10.9399 9.81591C10.9833 9.79065 11.029 9.76404 11.0773 9.73564C11.3617 9.56852 11.7204 9.34605 12 9.00686C12.2958 8.64793 12.48 8.18921 12.48 7.59375C12.48 6.12754 11.1477 5.0625 9.66727 5.0625C8.18677 5.0625 6.85449 6.12754 6.85449 7.59375C6.85449 7.90442 7.10632 8.15625 7.41699 8.15625C7.72766 8.15625 7.97949 7.90442 7.97949 7.59375Z"
          fill="#21A038"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.35449 9C2.35449 4.96142 5.62841 1.6875 9.66699 1.6875C13.7056 1.6875 16.9795 4.96142 16.9795 9C16.9795 13.0386 13.7056 16.3125 9.66699 16.3125C5.62841 16.3125 2.35449 13.0386 2.35449 9ZM9.66699 2.8125C6.24973 2.8125 3.47949 5.58274 3.47949 9C3.47949 12.4172 6.24973 15.1875 9.66699 15.1875C13.0842 15.1875 15.8545 12.4172 15.8545 9C15.8545 5.58274 13.0842 2.8125 9.66699 2.8125Z"
          fill="#21A038"
        />
      </svg>
    </>
  );
};

export default QestionIcon;
