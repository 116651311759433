import { parseISO, format } from "date-fns";
import style from "./styles.module.scss";

export const DateFormatter = ({
  date,
  className,
  loading,
  withTime = true,
}) => {
  let formattedDate = "";

  if (date) {
    const dateObj = parseISO(date);
    const formatTime = withTime ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy";

    formattedDate = format(dateObj, formatTime);
  }

  return (
    <p className={className}>
      {loading ? <div className={style.textSkeleton}></div> : formattedDate}
    </p>
  );
};
