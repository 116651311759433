export const getColor = (num) => {
  if (num >= 0 && num <= 6) {
    return "#21A038";
  } else if (num > 6 && num <= 10) {
    return "#FFBF00";
  } else if (num > 10) {
    return "#FF4D4D";
  } else {
    return "#AAAAAA";
  }
};
